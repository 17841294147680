import type { SiteConfig } from '@/_types'

export const siteConfig: SiteConfig = {
  name: 'xml2invoice',
  host: 'about.xml2invoice.com',

  languages: ['en', 'de'],
  i18nLocalesModules: {
    en: () => import('../locales/en.json'),
    de: () => import('../locales/de.json'),
  },

  siteTitle: 'XML2Invoice',
  baseUrl: 'https://about.xml2invoice.com',
  iconUrl: 'https://about.xml2invoice.com/logo.png',

  plausibleId: 'xml2invoice.com',
  plausibleAppId: 'xml2invoice.com',

  newsletterIdList: ['9aec0f46-557d-4328-b0c7-25fa1815ca7a'],

  repliesFaq: false,
  repliesProductName: 'XML2Invoice',

  // Activate social media sharing
  shareIntro: 'Found on "Viidoo - Document management for finances that learns!"',

  supportEmail: 'support@xml2invoice.com',
}
