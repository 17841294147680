<script setup lang="ts">
import AppFooter from '@/components/app-footer.vue'
import AppHeader from '@/components/app-header.vue'
import { useAppHeadHoltwick } from '@/lib/use-app-head-holtwick'
import { useExternalLinks } from '@/lib/use-external-links'
import { CircleHelp, CircleUser, Coins, Rocket } from 'lucide-vue-next'
import { OuiMobileActivator, OuiTooltipActivator } from 'oui-kit'
import logo from './_assets/logo.png'
import logo2x from './_assets/logo@2x.png'

import './app.styl'

useAppHeadHoltwick()
useExternalLinks()
</script>

<template>
  <AppHeader title="XML2Invoice" :logo="logo" :logo2x="logo2x">
    <a class="app-header-menu" href="https://xml2invoice.com?ref=homepage">
      <Rocket />
      <span>{{ $t("index.start") }}</span>
    </a>
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/help`">
      <CircleHelp />
      <span>{{ $t("index.help") }}</span>
    </router-link>
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/support`">
      <CircleUser />
      <span>{{ $t("support.title") }}</span>
    </router-link>
    <router-link class="app-header-menu" :to="`/${$i18n.locale}/buy`">
      <Coins />
      <span>{{ $t("index.buy") }}</span>
    </router-link>
    <!-- <a class="app-header-menu" href="https://xml2invoice.com">
      <ArrowDownToLine />
      <span>{{ $t("index.start") }}</span>
    </a> -->
  </AppHeader>
  <template v-if="$route.meta.pure">
    <router-view />
  </template>
  <template v-else>
    <main class="app-content">
      <Suspense>
        <router-view />
      </Suspense>
    </main>
  </template>
  <AppFooter
    link-holtwick
    support
    imprint
    privacy
    license
    mastodon
    press
  />
  <ClientOnly>
    <OuiTooltipActivator />
    <OuiMobileActivator />
  </ClientOnly>
</template>
